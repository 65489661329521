<template>
  <div>
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <h3 class="card-title " ><strong>{{fullName}}</strong> {{ $t("userRelationFacilitySearchPage.pageaTitle") }}</h3>
          </CCardHeader>
          <CCardBody>
            <!-- <p>宿を検索して、次の操作を実施します。</p>
            <div class="bd-example mb-5">
              <li class="h6">宿の情報を編集すること</li>
              <li class="h6">宿のオペレーターの指定すること</li>
              <li class="h6">宿のステータス・ダッシュボードを確認すること</li>
            </div> -->
            <p>{{ $t("userRelationFacilitySearchPage.facilitySearchGuide") }}</p>
            <CForm class="search-filter" @submit.prevent>
              <CRow class="mb-2">
                <CCol sm="1" class="search-btn">
                  <!-- #region 検索条件選択 -->
                  <label class="editor-title">{{
                    $t("userRelationFacilitySearchPage.searchCriteria")
                  }}</label>
                </CCol>
                <CCol sm="2">
                  <CSelect
                    class="search-lbl"
                    :options="queryOptions"
                    :value.sync="selectedQuery"
                  />
                  <!-- #endregion 検索条件選択 -->
                </CCol>
                <!-- #region 宿名検索 -->
                <template v-if="isSelectedQuery('facilityName')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('userRelationFacilitySearchPage.facilityNamePlaceholderText')
                      "
                      v-model="query.facilityName"
                      type="text"
                      @keydown.enter="onEnterKeyPress($event, 'facilityName')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('facilityName')"
                      class="info-button"
                      :disabled="!query.facilityName"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion 宿名検索 -->

                <!-- 拠点検索 -->
                <template v-if="isSelectedQuery('branch')">
                  <CCol sm="7" lg="6">
                    <CSelect
                      class="col-sm-12 px-1"
                      :options="branchOffice"
                      :value.sync="query.selectedBranch"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      class="info-button"
                      color="info"
                      @click="onSearchClicked('branch')"
                    >
                      <i class="icon cil-search"></i>
                      {{ $t("userRelationFacilitySearchPage.searchButton") }}
                    </CButton>
                  </CCol>
                </template>

                <!-- #region 全国地方公共団体コード検索 -->
                <!-- <template v-if="isSelectedQuery('areaId')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="$t('userRelationFacilitySearchPage.areaidPlaceholderText')"
                      v-model="query.areaId"
                      type="text"
                      @keydown.enter="onSearchQueryEnter($event, 'areaId')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('areaId')"
                      class="info-button"
                      :disabled="!query.areaId"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template> -->
                <!-- #endregion 全国地方公共団体コード検索 -->

                <!-- #region タグ名検索 -->
                <!-- 一時的にコメントアウトします-->
                <!-- <template v-if="isSelectedQuery('tagName')">
                  <CCol ssm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('userRelationFacilitySearchPage.tagNamePlaceholderText')
                      "
                      v-model="query.tagName"
                      type="text"
                      @keydown.enter="onSearchQueryEnter($event, 'tagName')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('tagName')"
                      class="info-button"
                      :disabled="!query.tagName"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template> -->
                <!-- #endregion タグ名検索 -->

                <!-- #region 郵便番号検索 -->
                <template v-if="isSelectedQuery('zipcode')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('userRelationFacilitySearchPage.zipcodePlaceholderText')
                      "
                      v-model="query.zipcode"
                      @keydown.enter="onEnterKeyPress($event, 'zipcode')"
                      type="text"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('zipcode')"
                      class="info-button"
                      :disabled="!query.zipcode"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion 郵便番号検索 -->

                <!-- #region 電話番号検索 -->
                <template v-if="isSelectedQuery('tel')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="$t('userRelationFacilitySearchPage.telPlaceholderText')"
                      v-model="query.tel"
                      @keydown.enter="onEnterKeyPress($event, 'tel')"
                      type="text"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('tel')"
                      class="info-button"
                      :disabled="!query.tel"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion 電話番号検索 -->

                <!-- #region エリア検索 -->
                <template v-if="isSelectedQuery('area')">
                   <CCol sm="7" lg="6">
                    <!-- #area dropdow region -->
                    <section class="area-filter-section d-inline-block col-sm-12 px-1">
                    
                      <CButton
                        color="secondary"
                        class="bg-white border-white mb-2"
                        variant="outline"
                        block
                        @click="areaListBox = !areaListBox"
                      >
                        <CIcon
                          class="float-left text-mikan"
                          name="cil-location-pin"
                        />
                        <span class="text-dark">{{query.selectedAreaInfo.areaId ? query.selectedAreaInfo.name: $t("facilitySearchPage.selectedArea") }}</span>
                      </CButton>
                      <CCard
                        v-if="areaListBox"
                        class="area-list-box border-white"
                        :key="reLoad"
                      >
                        <CCardBody>
                          <ul class="prefecture-list">
                            <li
                              class="area-list"
                              v-for="area in fullAreaList"
                              :key="area.name"
                            >

                              <span
                                class="core-area d-block"
                                @click="area.display=!area.display"
                              > {{area.name}}

                                <i
                                  class=" bi float-right"
                                  :class="area.display ? 'bi-chevron-down':'bi-chevron-right'"
                                ></i>
                              </span>
                              <transition name="slide">
                                <ul
                                  class="ken-list"
                                  v-if="area.display"
                                >
                                  <li
                                    v-for="ken in area.childs"
                                    :key="ken.areaId"
                                  >
                                    <span class="ken-title">
                                      <label
                                        :for="'flexCheckChecked'+ken.areaId"
                                        class="ml-1 my-1"
                                      >
                                        <!-- @click="isSelected(ken)" -->
                                        <input
                                          type="radio"
                                          :id="'flexCheckChecked'+ken.areaId"
                                          v-model="query.selectedAreaInfo"
                                          v-bind:value="ken"
                                        >

                                        {{ken.name}}
                                      </label>
                                      <i
                                        class="bi float-right mr-1 w-25 text-right"
                                        @click="onDisplayCityClicked(ken)"
                                        :class="ken.display ?  'bi-chevron-down':'bi-chevron-right'"
                                      ></i>
                                    </span>
                                    <transition name="slide">
                                      <ul
                                        class="city-list"
                                        v-if="ken.display"
                                      >
                                        <li
                                          v-for="city in ken.childs"
                                          :key="city.areaId"
                                        >
                                          <label
                                            :for="'flexCheckChecked'+city.areaId"
                                            class="ml-1 city-name"
                                          >
                                            <input
                                              type="radio"
                                              v-model="query.selectedAreaInfo"
                                              v-bind:value="city"
                                              :id="'flexCheckChecked'+city.areaId"
                                              class="city-check"
                                            >
                                            <span>{{city.name}}</span>
                                          </label>
                                        </li>
                                      </ul>
                                    </transition>
                                  </li>
                                </ul>
                              </transition>
                            </li>
                          </ul>

                          <CSpinner
                            color="info"
                            class="mx-auto d-block my-4"
                            v-if="spinnerSide"
                          />
                            <!-- style="width:4rem;height:4rem;" -->
                          <!-- {{query.selectedAreaInfo}} -->
                          <!-- <pre><code>{{fullAreaList}}</code></pre> -->
                        </CCardBody>
                        <CCardFooter>
                          <CButton
                            variant="outline"
                            color="dark"
                            @click="areaListBox= false"
                          >
                            {{$t('common.close')}}
                          </CButton>

                        </CCardFooter>
                      </CCard>
                    </section>
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      class="info-button"
                      color="info"
                      @click="onSearchClicked('areaIds')"
                    >
                      <i class="icon cil-search"></i>
                      {{ $t("facilitySearchPage.searchButton") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion エリア検索 -->

                </CRow>
            </CForm>
            <transition name="fade">
                <CRow v-if="facilityNotFound">
                  <CCol lg="6">
                    <CAlert color="danger">{{
                      $t("userRelationFacilitySearchPage.facilityNotFoundAlert")
                    }}</CAlert>
                  </CCol>
                </CRow>
            </transition>
          </CCardBody>
          <CCollapse :show="facilityCollapse" :duration="200">
            <CCardBody>
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{ $t("userRelationFacilitySearchPage.facilityList") }}</h4>
                    {{ $t("userRelationFacilitySearchPage.totalNumber") }}: {{facilityCount}} | {{ $t("userRelationFacilitySearchPage.resultNumber") }}: {{facilities.length}}
                  </dt>
                </dl>
                <CRow>
                  <CCol
                    sm="5"
                    class="paginationInput"
                  >
                    <label class="mr-2 float-left">{{$t('userRelationFacilitySearchPage.pagenationLabel')}}</label>
                    <CSelect
                      placeholder=""
                      horizontal
                      type="number"
                      :options="facilityPagenationCounts"
                      :value.sync="facilityPagenationSelected"
                    />
                <!-- <CRow class="mb-2">
                  <CCol
                    sm="3"
                    class="paginationInput"
                  >
                    <CSelect
                      :label="$t('userRelationFacilitySearchPage.pagenationLabel')"
                      placeholder=""
                      horizontal
                      type="number"
                      :options="facilityPagenationCounts"
                      :value.sync="facilityPagenationSelected"
                    /> -->
                  </CCol>
                </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="facilities"
                  :fields="facilityTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="facilityPagenationSelected"
                  pagination
                  v-if="!facilitiesLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                  :sorterValue.sync="tableSorter" -->
                  <td class="text-center" slot="branch" slot-scope="{ item }">
                    <strong>{{ item.branch }}</strong>
                  </td>
                  <td class="text-center" slot="uniqueId" slot-scope="{ item }">
                    <strong>{{ item.uniqueId }}</strong>
                  </td>
                  <td class="text-center" slot="name" slot-scope="{ item }">
                    <strong>{{ item.name }}</strong>
                  </td>
                  <td class="text-center" slot="zipcode" slot-scope="{ item }">
                    <strong>{{ item.zipcode }}</strong>
                  </td>
                  <td class="text-center" slot="address1" slot-scope="{ item }">
                    <strong>{{ item.address1 }}</strong>
                  </td>
                  <td class="text-center" slot="telephone" slot-scope="{ item }">
                    <strong>{{ item.telephone }}</strong>
                  </td>
                  <td class="text-center" slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  <template #facility_select="{ item }">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onManagementClicked(item.uniqueId)"
                      ><i class="icon cil-pencil mr-1"></i>{{ $t("userRelationFacilitySearchPage.facilitiesList.tableBody.managementButton") }}
                      </CButton>
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        v-if="!item.isRelation"
                        @click="onUserFacilityRelationSaveClicked(item)"
                      >{{ $t("userRelationFacilitySearchPage.facilitiesList.tableBody.facilityRelationButton") }}</CButton>
                      <span v-if="item.isRelation">
                        <i class="icon cil-check-circle"></i>{{ $t("userRelationFacilitySearchPage.facilitiesList.tableBody.facilityRelated") }}</span>
                    </td>
                  </template>
                </CDataTable>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>
        <!-- start_userFacilitiesTable -->
        <CCard>
          <CCardBody>
            <div class="user-info-table">
              <dl class="row">
                <dt class="col-sm-12">
                  <h4 class="sub-title">{{fullName}} {{ $t("userRelationFacilitySearchPage.userFacilityList.fasilityUserList") }}</h4>
                  <p><span>{{ $t("userRelationFacilitySearchPage.userFacilityList.facilityUserNumber") }}</span>: {{userFacilities.length}}</p>
                </dt>
              </dl>
              <transition name="fade">
                <CRow v-if="userFacilitiesNotFound">
                  <CCol lg="6">
                    <!-- TODO: -->
                    <CAlert color="danger">{{fullName}} {{ $t("userRelationFacilitySearchPage.userFacilityList.facilityUserNotfound") }}</CAlert>
                  </CCol>
                </CRow>
              </transition>
              <CCollapse :show="userCollapse" :duration="200">
                <div>
                  <CRow class="mb-2">
                  <CCol sm="3" class="paginationInput" >
                    <CSelect
                      :label="$t('userRelationFacilitySearchPage.pagenationLabel')"
                      placeholder=""
                      horizontal
                      type="number"
                      :options="userFacilityPagenationCounts"
                      :value.sync="userFacilityPagenationSelected"
                    />
                  </CCol>
                  </CRow>
                  <CDataTable
                    class="mb-0 table-outline"
                    hover
                    size="sm"
                    :items="userFacilities"
                    :fields="userFacilitiesTableFields"
                    head-color="light"
                    table-filter
                    column-filter
                    sorter
                    itemps-per-page-select
                    :items-per-page="userFacilityPagenationSelected"
                    pagination
                  >
                    <td class="text-center" slot="branch" slot-scope="{ item }">
                      <strong>{{ item.branch }}</strong>
                    </td>
                    <td class="text-center" slot="uniqueId" slot-scope="{ item }">
                      <strong>{{ item.uniqueId }}</strong>
                    </td>
                    <td class="text-center" slot="name" slot-scope="{ item }">
                      <strong>{{ item.name }}</strong>
                    </td>
                    <td class="text-center" slot="zipcode" slot-scope="{ item }">
                      <strong>{{ item.zipcode }}</strong>
                    </td>
                    <td class="text-center" slot="address1" slot-scope="{ item }">
                      <strong>{{ item.address1 }}</strong>
                    </td>
                    <td class="text-center" slot="telephone" slot-scope="{ item }">
                      <strong>{{ item.telephone }}</strong>
                    </td>
                    <td class="text-center" slot="email" slot-scope="{ item }">
                      <strong>{{ item.email }}</strong>
                    </td>
                    <template #facility_select="{item}">
                      <td class="py-2 text-center">
                        <CButton
                          color="info"
                          square
                          block
                          size="sm"
                          @click="onDeleteClicked(item)"
                        >{{$t("userRelationFacilitySearchPage.userFacilityList.tableBody.userRelationDeleteButton")}}</CButton>
                      </td>
                    </template>
                  </CDataTable>
                </div>
              </CCollapse>
            </div>
          </CCardBody>
          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <!-- start_relationSaveConfirmModal -->
    <CModal
      color="info"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="relationSaveConfirmModal"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalTitle")}}</h5>
      </template>
      <CRow class="mb-2">
        <CCol lg="12">
          <p>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.modalGuide")}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="12">
          <p><strong>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.relationFacility")}}</strong></p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.facilityName")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestFacility.name}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="12">
          <p><strong>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.relationUser")}}</strong></p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.userName")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.userName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.name")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{fullName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.modalContent.email")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.email}}</p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="relationSaveConfirmModal = false"
          color="secondary"
          class="float-left"
        >
          <i class="icon cil-x"></i>
          {{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.cancelButton")}}
        </CButton>
        <CButton
          color="success"
          @click="saveUserFacilityRelation()"
        >
          <i class="icon cil-save"></i>
          {{$t("userRelationFacilitySearchPage.relationSaveConfirmModal.relationSaveButton")}}
        </CButton>
      </template>
    </CModal>
    <!-- end_relationSaveConfirmModal -->

    <!-- start_relationSaveCompleteModal -->
    <CModal
      :show.sync="relationSaveCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationSaveCompleteModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("userRelationFacilitySearchPage.relationSaveCompleteModal.modalContent.completeResult")}}</p>

      <template #footer>
        <CButton
          @click="relationSaveCompleteModal = false"
          color="secondary"
        >{{$t("userRelationFacilitySearchPage.relationSaveCompleteModal.closeButton")}}</CButton>
      </template>
    </CModal>
    <!-- end_relationSaveCompleteModal -->

    <!-- start_relationDeleteConfirmModal -->
    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="relationDeleteConfirmModal"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.modalTitle")}}</h5>
      </template>

      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.name")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestFacility.name}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.email")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestFacility.email}}</p>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="12">
          <p>{{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.modalContent.modalGuide")}}</p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="relationDeleteConfirmModal = false"
          color="secondary"
          class="float-left"
        >
          <i class="icon cil-x"></i>
          {{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.cancelButton")}}
        </CButton>
        <CButton
          color="danger"
          @click="deleteUserFacilityRelation()"
        >
          <i class="icon cil-save"></i>
          {{$t("userRelationFacilitySearchPage.relationDeleteConfirmModal.deleteButton")}}
        </CButton>
      </template>
    </CModal>
    <!-- end_relationDeleteConfirmModal -->

    <!-- start_relationDeleteCompleteModal -->
    <CModal
      :show.sync="relationDeleteCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationDeleteCompleteModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("userRelationFacilitySearchPage.relationDeleteCompleteModal.modalContent.modalGuide")}}</p>

      <template #footer>
        <CButton
          @click="relationDeleteCompleteModal = false"
          color="secondary"
        >{{$t("userRelationFacilitySearchPage.relationDeleteCompleteModal.closeButton")}}</CButton>
      </template>
    </CModal>
    <!-- end_relationDeleteCompleteModal -->

    <!-- start_relationSaveErrorModal -->
    <CModal
      :show.sync="relationSaveErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationSaveErrorModal.modalTitle")}}</h5>
      </template>
      <p>{{$t("userRelationFacilitySearchPage.relationSaveErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("userRelationFacilitySearchPage.relationSaveErrorModal.modalContent.errorSolution")}}</p>

      <template #footer>
        <CButton
          @click="relationSaveErrorModal = false"
          color="secondary"
        >{{$t("userRelationFacilitySearchPage.relationSaveErrorModal.closeButton")}}</CButton>
      </template>
    </CModal>
    <!-- end_relationSaveErrorModal -->

    <!-- start_relationDeleteErrorModal -->
    <CModal
      :show.sync="relationDeleteErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("userRelationFacilitySearchPage.relationDeleteErrorModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("userRelationFacilitySearchPage.relationDeleteErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("userRelationFacilitySearchPage.relationDeleteErrorModal.modalContent.errorSolution")}}</p>

      <template #footer>
        <CButton
          @click="relationDeleteErrorModal = false"
          color="secondary"
        >{{$t("userRelationFacilitySearchPage.relationDeleteErrorModal.closeButton")}}</CButton>
      </template>
    </CModal>
    <!-- end_relationDeleteErrorModal -->
  </div>
</template>

<script>
import i18n from "../../../i18n"
import axios from "axios"

export default {
  // components: { Loading },
  name: "UserRelation",
  data() {
    return {
      facilityNotFound: false,
      facilitiesLoading: false,
      areaListBox: false,
      spinnerSide: true,
      reLoad: 0,
      userLoading: false,
      facilityCollapse: false,
      userCollapse: false,
      collapseDuration: 0,
      facilityCount: 0,
      userFacilitiesNotFound: false,
      relationSaveCompleteModal: false,
      relationSaveErrorModal: false,
      relationSaveConfirmModal: false,
      relationDeleteConfirmModal: false,
      relationDeleteCompleteModal: false,
      relationDeleteErrorModal: false,
      facilityPagenationCounts: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 50 },
      ],
      facilityPagenationSelected: 5,
      userFacilityPagenationCounts: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 50 },
      ],
      userFacilityPagenationSelected: 5,

      /** 選択中の検索条件 */
      selectedQuery: "facilityName",

      /** 検索クエリ */
      query: {
        // areaId: "",
        tagName: "",
        zipcode: "",
        tel: "",
        facilityName: "",
        selectedBranch:"",
        selectedAreaInfo:{}
      },

      /**支店一覧 */
      branchOffice:[],

      /**全エリア情報 */
      fullAreaList :[],

       /** 検索条件選択 */
      queryOptions: [
        {value: "facilityName",label: `宿名`,},
        {value: "branch",label: `拠点`,},
        // { value: "areaId", label: `全国地方公共団体コード` },
        // { value: "tagName", label: i18n.t("userRelationFacilitySearchPage.tagName") },
        { value: "zipcode", label: `郵便番号` },
        { value: "tel", label: `電話番号` },
        { value: "area", label: `エリア` }
      ],

      // 宿検索結果
      facilities: [],

      // 所有している宿一覧
      userFacilities: [],

      // 関連付けするユーザーデータ
      requestUser: {
        id: "",
        firstName: "",
        lastName: "",
        familyName:"",
        givenName:"",
        email: "",
        telephone: "",
        description: "",
        status: 1,
        type: "10",
      },

      // リクエストに使う宿情報
      requestFacility: {
        uniqueId: "",
        name: "",
        email: "",
      },

      facilityTableFields: [
        {
          key: "branch",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.branch"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "uniqueId",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.id"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "name",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.name"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "zipcode",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.zipcode"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "address1",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.address"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "telephone",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.telephone"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "facility_select",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.action"
          )}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        },
      ],

      userFacilitiesTableFields: [
        {
          key: "branch",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.branch"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "uniqueId",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.id"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "name",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.userFacilityList.tableHeader.name"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "zipcode",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.zipcode"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "address1",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.userFacilityList.tableHeader.address"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "telephone",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.userFacilityList.tableHeader.telephone"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "facility_select",
          label: `${i18n.t(
            "userRelationFacilitySearchPage.facilitiesList.tableHeader.action"
          )}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        },
      ],
    };
  },
  created() {
    this.getRelationUser(this.$route.params.userId);
    // alert(this.requestUser.id)
  },
  computed: {

    fullName(){
      const familyName = this.requestUser.familyName;
      const givenName = this.requestUser.givenName;
      const fullName = `${familyName} ${givenName}`;
      return fullName;
    },

  },
  methods: {

    //#region event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    /**エンターキー押下 */
    onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        // case "areaId":
        //   if (!this.query.areaId) return;
        //   break;
        case "tagName":
          if (!this.query.tagName) return;
          break;
        case "zipcode":
          if (!this.query.zipcode) return;
          break;
        case "tel":
          if (!this.query.tel) return;
          break;
        case "facilityName":
          if (!this.query.facilityName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },

    /**検索ボタン押下 */
    onSearchClicked(value) {
      // 宿検索API URL
      let urlRequest;

      switch (value) {
        // case "areaId":
        //   urlRequest = this.makeUrlRequest(value, this.query.areaId);
        //   break;
        case "tagName":
          urlRequest = this.makeUrlRequest(value, this.query.tagName);
          break;
        case "zipcode":
          urlRequest = this.makeUrlRequest(value, this.query.zipcode);
          break;
        case "tel":
          urlRequest = this.makeUrlRequest(value, this.query.tel);
          break;
        case "facilityName":
          urlRequest = this.makeUrlRequest(value, this.query.facilityName);
          break;
        case "branch":
          urlRequest = this.makeUrlRequest(value, this.query.selectedBranch);
          break;
        case "areaIds":
          urlRequest = this.makeUrlRequest(value, this.query.selectedAreaInfo.areaId);
          break;
      }

      if (!urlRequest) {
        // "検索条件を入力してください"
        alert(this.$i18n.t("userRelationFacilitySearchPage.noQueryAlert"));
        return;
      }

      this.getFacilities(urlRequest);
    },

    /**宿管理ボタン押下 */
    onManagementClicked(uniqueId){
      this.facilityEditPage(uniqueId);
    },

    /**宿xユーザーのリレーション登録ボタン押下 */
    onUserFacilityRelationSaveClicked(facility) {
      console.log(facility);

      //リクエストに使う宿情報作成
      this.requestFacility.name = facility.name;
      this.requestFacility.uniqueId = facility.uniqueId;

      console.log(this.requestFacility.uniqueId);
      // ユーザーのタイプ選択モーダル表示
      this.showRelationSaveConfirmModal();
    },

    /**エリア一覧 都道府県表示押下 */
    onDisplayCityClicked(ken) {
      ken.display = !ken.display;
    },

    /**宿の経営者関連付け削除ボタン押下 */
    onDeleteClicked(facility) {
      //リクエストに使う宿情報作成
      this.requestFacility.uniqueId = facility.uniqueId;
      this.requestFacility.name = facility.name;
      this.requestFacility.email = facility.email;
      console.log(this.requestFacility.uniqueId);
      console.log(this.requestFacility.name);
      console.log(this.requestFacility.email);

      this.showRelationDeleteConfirmModal();
    },


    //#endregion event

    //#region method

    /**
     * リクエストURL作成
     * @param key 検索クエリキー
     * @param value 検索クエリ値
     */
    makeUrlRequest(key, value) {
      const isKeyAndValue = !key || !value;
      return isKeyAndValue ? "" : `/rest/hotel/?${key}=${value}`;
      // return isKeyAndValue ? "" : `http://localhost:8080/rest/hotel/?${key}=${value}`;
    },

    /**前の画面へ戻る */
    backPage() {
      this.$router.go(-1);
    },

    /** 選択中の検索クエリか？ */
    isSelectedQuery(query) {
      return this.selectedQuery === query;
    },

    /**検索結果のテーブルボディを作成 */
    createSearchFacilityTableBodies(facilities){
      // 検索内容リストリセット
      this.facilities = [];

      facilities.forEach(facility => {

        // 検索した各宿をオブジェクトとして作成
        const facilityObj = {}
        const nullLabel = `${i18n.t("userRelationFacilitySearchPage.facilitiesList.tableBody.notSet")}`

        facilityObj.uniqueId = facility.uniqueId;
        facilityObj.name = facility.name ? facility.name : nullLabel;
        facilityObj.branch = facility.branch ? facility.branch.name : nullLabel;
        facilityObj.zipcode = facility.zipcode ? facility.zipcode : nullLabel;
        facilityObj.address1 = facility.address1 ? facility.address1 : nullLabel;
        facilityObj.telephone = facility.telephone ? facility.telephone : nullLabel;
        facilityObj.email = facility.email ? facility.email : nullLabel;
        facilityObj.isRelation = false;

        this.facilities.push(facilityObj);
      });
    },
    /**検関連づいた宿一覧テーブルボディを作成 */
    createUserFacilityTableBodies(facilities){
      // 検索内容リストリセット
      this.userFacilities = [];

      facilities.forEach(facility => {
        // 検索した各宿をオブジェクトとして作成
        const facilityObj = {}
        const nullLabel = `${i18n.t("userRelationFacilitySearchPage.facilitiesList.tableBody.notSet")}`

        facilityObj.uniqueId = facility.uniqueId;
        facilityObj.name = facility.name ? facility.name : nullLabel;
        facilityObj.branch = facility.branch ? facility.branch.name : nullLabel;
        facilityObj.zipcode = facility.zipcode ? facility.zipcode : nullLabel;
        facilityObj.address1 = facility.address1 ? facility.address1 : nullLabel;
        facilityObj.telephone = facility.telephone ? facility.telephone : nullLabel;
        facilityObj.email = facility.email ? facility.email : nullLabel;
        facilityObj.isRelation = false;

        this.userFacilities.push(facilityObj);
      });
    },

    /**リレーション状態を初期化 */
    refreshIsRelationStatus() {
      this.facilities.forEach((a) => {
        // チェック前にRelation状態を初期化
        // NOTE:これを削除するとテーブル内の宿関連付けボタンの表示更新がおかしくなる
        a.isRelation = false;
        // 所有している宿の中で検索した宿IDが存在しているか
        a.isRelation = this.userFacilities.some(
          (b) => a.uniqueId === b.uniqueId
        );
      });
    },

    /**宿編集画面へ遷移 */
    facilityEditPage(uniqueId) {
      this.$router.push({
        path: "/operator/facility/edit"
      });
      this.$store.commit('setUid',uniqueId);
    },

    /**宿の登録件数取得 */
    getFacilityTotalNumber() {
      //Login as registered user
      var urlRequest = `/rest/hotel`;
      axios
        .get(urlRequest)
        .then((resp) => {
          //レスポンスを格納
          this.facilityCount = resp.data.total;
        })
        .catch((err) => {
          this.PassError = err.response.status;
          console.log(err);
        });
    },

    /**関連付け保存確認モーダル表示 */
    showRelationSaveConfirmModal() {
      // ユーザータイプ初期化
      this.requestUser.type = "10";
      this.relationSaveConfirmModal = true;
    },

    /**削除確認モーダル表示 */
    showRelationDeleteConfirmModal() {
      this.relationDeleteConfirmModal = true;
    },


    //#endregion method

    //#region request

    // 関連付けするユーザー取得
    getRelationUser(userId) {
      // var urlRequest = `http://localhost:8080/rest/user/${userId}`;
      var urlRequest = `/rest/user/${userId}`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          // レスポンス格納
          const user = resp.data;
          //宿に関連付けするユーザー情報作成
          this.requestUser.id = user.id;
          this.requestUser.userName = user.userName;
          this.requestUser.firstName = user.firstName;
          this.requestUser.lastName = user.lastName;
          this.requestUser.familyName = user.familyName;
          this.requestUser.givenName = user.givenName;
          this.requestUser.email = user.email;
          this.requestUser.telephone = user.telephone;
          this.requestUser.description = "";
          this.requestUser.status = user.status;

          // console.log(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**宿検索 */
    getFacilities(urlRequest) {
      // 宿が見つからない際のメッセージ表示フラグ
      this.facilityNotFound = false;
      // 検索結果初期化
      this.facilities = [];

      axios
        .get(urlRequest)
        .then((resp) => {
          //レスポンスを格納
          // this.facilities = resp.data;
          this.createSearchFacilityTableBodies(resp.data);

          this.facilities.length === 0
            ? ((this.facilityCollapse = false), (this.facilityNotFound = true))
            : ((this.facilityCollapse = true), (this.facilityNotFound = false));

          this.areaListBox = false;
          this.reLoad++;

          // console.log(this.facilities);
          // console.log(this.userFacilities);

          this.refreshIsRelationStatus();
        })
        .catch((err) => {
          console.log(err);
          this.facilityNotFound = true;
        });
    },

    /**宿xユーザーのリレーション登録実行 */
    saveUserFacilityRelation() {
      // ラジオボタンのvalue(string)→numberに変換
      // const requestTypeNum = Number(this.requestUser.type);
      // →※ユーザータイプは宿オペレーターのみの為、固定値を送信する
      const requestTypeNum = 10;
      // postパラメータ
      var requestParm = {
        uniqueId: this.requestFacility.uniqueId,
        // 宿オペレーターのみが関連づくためタイプは固定値
        type: requestTypeNum, // Operator = 10, Staff = 20
      };

      //Login as registered user
      var urlRequest = `/rest/user/${this.requestUser.id}/hotel`;
      // var urlRequest = `http://localhost:8080/rest/user/${this.requestUser.id}/hotel`;
      axios
        .post(urlRequest, requestParm, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log(resp.data);
           // 所有宿一覧リセット
          this.userFacilities=[];
          // 所有宿一覧アラートリセット
          this.userFacilitiesNotFound = false;
          // 所有宿再取得
          const callback = () => this.refreshIsRelationStatus();
          this.getUserFacilities(this.requestUser.id, callback);
          this.relationSaveConfirmModal = false;
          this.relationSaveCompleteModal = true;
        })
        .catch((err) => {
          console.log(err);
          // 処理失敗
          this.relationSaveConfirmModal = false;
          this.relationSaveErrorModal = true;
        });
    },


    /**宿xユーザーのリレーション削除実行 */
    deleteUserFacilityRelation() {
      //Login as registered user
      var urlRequest = `/rest/user/${this.requestUser.id}/hotel/${this.requestFacility.uniqueId}`;
      // var urlRequest = `http://localhost:8080/rest/user/${this.requestUser.id}/hotel/${this.requestFacility.uniqueId}`;
      axios
        .delete(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          console.log(resp.data);
          // 所有宿一覧リセット
          this.userFacilities=[];
          // 所有宿一覧アラートリセット
          this.userFacilitiesNotFound = false;
          // 所有宿再取得
          const callback = () => this.refreshIsRelationStatus();
          this.getUserFacilities(this.requestUser.id, callback);
          this.relationDeleteConfirmModal = false;
          this.relationDeleteCompleteModal = true;
        })
        .catch((err) => {
          console.log(err);
          // 処理失敗
          this.relationDeleteConfirmModal = false;
          this.relationDeleteErrorModal = true;
        });
    },


    /**所有宿取得 */
    getUserFacilities(userId, callback) {
      //Login as registered user
      // var urlRequest = ` http://localhost:8080/rest/user/${userId}/hotel`;
      var urlRequest = `/rest/user/${userId}/hotel`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          //レスポンスを格納
          // this.userFacilities = resp.data.hotels;
          this.createUserFacilityTableBodies(resp.data.hotels);

          this.userFacilities.length === 0
            ? ((this.userCollapse = false), (this.userFacilitiesNotFound = true))
            : ((this.userCollapse = true), (this.userFacilitiesNotFound = false));
          
          // 引数にメソッドが入ってるとき(登録・削除)にリレーション再チェック
          if(callback !==undefined){
            callback();
            console.log("callback");
          }

        })
        .catch((err) => {
          this.userFacilities.length === 0
            ? ((this.userCollapse = false), (this.userFacilitiesNotFound = true))
            : ((this.userCollapse = true), (this.userFacilitiesNotFound = false));
          console.log(err);

           if (err.response.status == 404) {
            if(callback !==undefined){
              callback();
              console.log("callback");
            }
           }
        });
    },

   
    /**支店情報取得 */
    getAllBranchList() {
      var urlRequest = `/rest/branch?type=s`;
      axios
        .get(urlRequest)
        .then((resp) => {
          const branchList = resp.data.list;

          branchList.forEach(branch => {
            const branchObj = {}
              branchObj.value = branch.id;
              branchObj.label = branch.name;
              this.branchOffice.push(branchObj);
          });

          //先頭に空の要素追加
          this.branchOffice.unshift({value : "",label : `${i18n.t("common.selectGuid")}`})
          
          console.log(this.branchOffice)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**エリア情報取得 */
    getAllAreaList() {
      var urlRequest = `/rest/area/arealist`;
      axios
        .get(urlRequest)
        .then((resp) => {
          var areList = resp.data.areas;
          this.fullAreaList = areList.map((a) => {
            a.display = false;
            if (a.childs) {
              a.childs.forEach((b) => {
                b.display = false;
                return b;
              });
            }
            return a;
          });
          this.spinnerSide = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //#endregion request

  },
  beforeMount() {
    // 全宿件数取得
    this.getFacilityTotalNumber();
    // alert(this.requestUser.id)
    this.getUserFacilities(this.$route.params.userId);
    this.getAllBranchList();
    this.getAllAreaList();
  },
};
</script>

<style lang="scss" scoped>

  .editor-title {
    font-size: 1.1em;
    border-left: 2px solid #194583;
    padding: 0 0 0 5px;
    margin-bottom: 10px;
  }

  .area-list-box {
      position: absolute;
      border: 1px solid #cccccc;
      min-width: 400px;
      background: #ffffff;
      z-index: 2;
    }
    .core-filter-section {
      position: relative;

      .select-date {
        position: relative;
        .custom-datepicker {
          position: absolute;
          z-index: 2;
          left: 0px;
          top: 50px;
        }
      }
    }
.prefecture-list {
  max-height: 58vh;
  overflow-y: auto;
  label,
  .core-area,
  .bi {
    cursor: pointer;
    margin: 0 !important;
  }
  li {
    clear: both;

    &.area-list {
      margin-bottom: 2px;
    }
    ul {
      margin-left: 20px;
    }
    .city-list {
      li {
        border-bottom: 1px solid #cccccc;
        display: block;
        margin-bottom: 2px;
        &:last-child {
          border: none;
        }
        label {
          margin: 0 !important;
          padding: 0 !important;
        }
        .city-name {
          display: flex;
          .city-check {
            margin: 7px 5px 0 0;
          }
        }
      }
    }
    .ken-list {
      .ken-title {
        border-bottom: 1px solid #cccccc;
        display: block;
        // border-radius: 4px;
        padding: 0;
        margin-top: 2px;
        label {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
}
.core-area {
  border-bottom: 1px solid #cccccc;
  display: block;
  // border-radius: 4px;
  padding: 0;
  .ic {
    margin-top: 5px;
  }
}
</style>